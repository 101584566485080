import React, { useContext, useMemo } from 'react';

import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  redirect,
  Route,
  RouterProvider,
  useHref,
  useParams,
  useRouteError,
  useSearchParams,
} from 'react-router-dom';
import { OrchimateConfigProvider, useOrchimateConfig } from './config';
import { Home, SpecHome } from './Home';
import { Layout } from './Layout';
import {
  OrchestraSpecManagerProvider,
  OrchestraSpecWithInfoContext,
  useOrchestraSpec,
  useOrchestraSpecManager,
} from './OrchestraSpecManager';
import {
  CodeSetView,
  ComponentView,
  DatatypeView,
  FieldView,
  GroupView,
  MessageView,
} from './OrchestraSpecViews';
import { Container, Skeleton, ThemeProvider, Typography } from '@mui/material';
import {
  CODE_SETS_TITLE,
  CodeSetIndexView,
  ComponentIndexView,
  COMPONENTS_TITLE,
  DatatypeIndexView,
  DATATYPES_TITLE,
  FieldIndexView,
  FIELDS_TITLE,
  GroupIndexView,
  GROUPS_TITLE,
  MessageIndexView,
  MESSAGES_TITLE,
} from './OrchestraSpecIndexViews';
import { SearchView } from './SearchView';
import { SpecSearchContextProvider, useSpecSearch } from './specSearch';
import { PathContextProvider } from './Path';
import { InternalLink } from './InternalLink';
import { ErrorPage } from './ErrorPage';
import { theme } from './theme';
import { ErrorBoundary } from 'react-error-boundary';
import { SpecContextFathomTracking, useFathom } from './fathom';
import {
  ORCH_HUB_GROUP_SPEC_ROUTE_ID,
  ORCH_HUB_OFFICIAL_SPEC_ROUTE_ID,
  ORCHIMATE_SPEC_ROUTE_ID,
} from './routing';
import { useSpecIdentifier } from './specIdentifier';
import { HelmetProvider } from 'react-helmet-async';
import {
  OrchestraResource,
  OrchestraResourceBase,
  OrchestraResourceTypeInfos,
  OrchestraSpec,
} from './OrchestraSpec';
import { LeafTitle, OrchimatePageMetadata } from './OrchimatePageMetadata';
import {
  blockTrackingForMe,
  enableTrackingForMe,
  isTrackingEnabled,
} from 'fathom-client';

const homeDescription = process.env.REACT_APP_SITE_DESCRIPTION;

function NotFound() {
  return (
    <Container maxWidth='lg'>
      <OrchimatePageMetadata leafTitle='Not Found' noIndex={true} />
      <Typography variant='h4' component='h2'>
        Not Found
      </Typography>
      <Typography variant='body1' sx={{ mt: 2 }}>
        <InternalLink to='/'>&raquo; Return home</InternalLink>
      </Typography>
    </Container>
  );
}

type WithPageMetadataProps = {
  leafPageTitle?: LeafTitle;
  description?: string;
};

function PageMetadata(props: WithPageMetadataProps) {
  const config = useOrchimateConfig();
  const href = useHref('.');
  const baseUrl = config?.baseUrl;
  const canonicalUrl = baseUrl !== undefined ? `${baseUrl}${href}` : undefined;

  return (
    <OrchimatePageMetadata
      leafTitle={props.leafPageTitle}
      description={props.description}
      canonicalUrl={canonicalUrl}
    />
  );
}

function useIndexDescription(leafPageTitle: string) {
  const { spec } = useContext(OrchestraSpecWithInfoContext) || {};
  if (spec === undefined) {
    return;
  }

  return `${leafPageTitle} from ${spec.name}`;
}

function useResourceDescription(resource?: OrchestraResourceBase) {
  const { spec } = useContext(OrchestraSpecWithInfoContext) || {};
  if (resource === undefined || spec === undefined) {
    return;
  }

  const resourceTypeName = OrchestraResourceTypeInfos[resource.type].name;
  return `${resource.name} ${resourceTypeName} from ${spec.name}`;
}

function useSearchDescription(searchQuery?: string) {
  const { spec } = useContext(OrchestraSpecWithInfoContext) || {};
  if (spec === undefined) {
    return;
  }

  if (searchQuery !== undefined) {
    return `Search results for "${searchQuery}" in ${spec.name}`;
  }

  return `Search ${spec.name}`;
}

function HomeRoute() {
  return (
    <>
      <PageMetadata description={homeDescription} />
      <Home />
    </>
  );
}

function SpecHomeRoute() {
  const { spec } = useContext(OrchestraSpecWithInfoContext) || {};
  return (
    <>
      <PageMetadata
        description={spec && `Orchestra specification for ${spec.name}`}
      />
      <SpecHome orchestraSpec={spec} />
    </>
  );
}

/**
 * Returns true if {@code spec} is present, but {@code resource} is not, indicating a not-found situation.
 */
function resourceNotFound(
  spec?: OrchestraSpec,
  resource?: OrchestraResource
): boolean {
  return spec !== undefined && resource === undefined;
}

function CodeSetRoute() {
  const { spec } = useContext(OrchestraSpecWithInfoContext) || {};
  const { codeSetName } = useParams();
  const codeSet = codeSetName ? spec?.getCodeSetByName(codeSetName) : undefined;
  const description = useResourceDescription(codeSet);
  const usedIn = codeSet && spec?.getCodeSetUsedIn(codeSet);

  if (resourceNotFound(spec, codeSet)) {
    return <NotFound />;
  }

  return (
    <>
      <PageMetadata leafPageTitle={codeSet?.name} description={description} />
      <CodeSetView codeSet={codeSet} usedIn={usedIn} />
    </>
  );
}

function CodeSetIndexRoute() {
  const { spec } = useContext(OrchestraSpecWithInfoContext) || {};
  const description = useIndexDescription(CODE_SETS_TITLE);
  return (
    <>
      <PageMetadata leafPageTitle={CODE_SETS_TITLE} description={description} />
      <CodeSetIndexView codeSets={spec?.getCodeSets()} />
    </>
  );
}

function ComponentRoute() {
  const { spec } = useContext(OrchestraSpecWithInfoContext) || {};
  const { componentName } = useParams();
  const component = componentName
    ? spec?.getComponentByName(componentName)
    : undefined;
  const description = useResourceDescription(component);
  const usedIn = component && spec?.getComponentUsedIn(component);

  if (resourceNotFound(spec, component)) {
    return <NotFound />;
  }

  return (
    <>
      <PageMetadata leafPageTitle={component?.name} description={description} />
      <ComponentView component={component} usedIn={usedIn} />
    </>
  );
}

function ComponentIndexRoute() {
  const { spec } = useContext(OrchestraSpecWithInfoContext) || {};
  const description = useIndexDescription(COMPONENTS_TITLE);
  return (
    <>
      <PageMetadata
        leafPageTitle={COMPONENTS_TITLE}
        description={description}
      />
      <ComponentIndexView components={spec?.getComponents()} />
    </>
  );
}

function DatatypeRoute() {
  const { spec } = useContext(OrchestraSpecWithInfoContext) || {};
  const { datatypeName } = useParams();
  const datatype = datatypeName
    ? spec?.getDatatypeByName(datatypeName)
    : undefined;
  const description = useResourceDescription(datatype);

  if (resourceNotFound(spec, datatype)) {
    return <NotFound />;
  }

  return (
    <>
      <PageMetadata leafPageTitle={datatype?.name} description={description} />
      <DatatypeView datatype={datatype} />
    </>
  );
}

function DatatypeIndexRoute() {
  const { spec } = useContext(OrchestraSpecWithInfoContext) || {};
  const description = useIndexDescription(DATATYPES_TITLE);
  return (
    <>
      <PageMetadata leafPageTitle={DATATYPES_TITLE} description={description} />
      <DatatypeIndexView datatypes={spec?.getDatatypes()} />
    </>
  );
}

function FieldRoute() {
  const { spec } = useContext(OrchestraSpecWithInfoContext) || {};
  const { fieldName } = useParams();
  const field = fieldName ? spec?.getFieldByName(fieldName) : undefined;
  const description = useResourceDescription(field);
  const usedIn = field && spec?.getFieldUsedIn(field);

  if (resourceNotFound(spec, field)) {
    return <NotFound />;
  }

  return (
    <>
      <PageMetadata leafPageTitle={field?.name} description={description} />
      <FieldView field={field} usedIn={usedIn} />
    </>
  );
}

function FieldIndexRoute() {
  const { spec } = useContext(OrchestraSpecWithInfoContext) || {};
  const description = useIndexDescription(FIELDS_TITLE);
  return (
    <>
      <PageMetadata leafPageTitle={FIELDS_TITLE} description={description} />
      <FieldIndexView fields={spec?.getFields()} />
    </>
  );
}

function GroupRoute() {
  const { spec } = useContext(OrchestraSpecWithInfoContext) || {};
  const { groupName } = useParams();
  const group = groupName ? spec?.getGroupByName(groupName) : undefined;
  const description = useResourceDescription(group);
  const usedIn = group && spec?.getGroupUsedIn(group);

  if (resourceNotFound(spec, group)) {
    return <NotFound />;
  }

  return (
    <>
      <PageMetadata leafPageTitle={group?.name} description={description} />
      <GroupView group={group} usedIn={usedIn} />
    </>
  );
}

function GroupIndexRoute() {
  const { spec } = useContext(OrchestraSpecWithInfoContext) || {};
  const description = useIndexDescription(GROUPS_TITLE);
  return (
    <>
      <PageMetadata leafPageTitle={GROUPS_TITLE} description={description} />
      <GroupIndexView groups={spec?.getGroups()} />
    </>
  );
}

function MessageRoute() {
  const { spec } = useContext(OrchestraSpecWithInfoContext) || {};
  const { messageName } = useParams();
  const message = messageName ? spec?.getMessageByName(messageName) : undefined;
  const description = useResourceDescription(message);

  if (resourceNotFound(spec, message)) {
    return <NotFound />;
  }

  return (
    <>
      <PageMetadata leafPageTitle={message?.name} description={description} />
      <MessageView message={message} />
    </>
  );
}

function MessageIndexRoute() {
  const { spec } = useContext(OrchestraSpecWithInfoContext) || {};
  const description = useIndexDescription(MESSAGES_TITLE);
  return (
    <>
      <PageMetadata leafPageTitle={MESSAGES_TITLE} description={description} />
      <MessageIndexView messages={spec?.getMessages()} />
    </>
  );
}

function SearchRoute() {
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get('q');
  const specSearch = useSpecSearch();

  const leafPageTitle = ['Search'];

  const truncatedSearchQuery =
    searchQuery !== null ? truncateSearchQuery(searchQuery) : undefined;
  if (truncatedSearchQuery !== undefined) {
    leafPageTitle.unshift(truncatedSearchQuery);
  }

  const description = useSearchDescription(truncatedSearchQuery);

  // Note: we don't currently set a canonical URL here; should it contain the search query?
  return (
    <>
      <OrchimatePageMetadata
        leafTitle={leafPageTitle}
        description={description}
      />
      <SearchView specSearch={specSearch} searchQuery={searchQuery || ''} />
    </>
  );
}

function truncateSearchQuery(searchQuery: string) {
  if (searchQuery.length <= 50) {
    return searchQuery;
  }

  return searchQuery.slice(0, 50) + '…';
}

// Parent route to all routes to provide a spec manager
function OuterRoute() {
  const config = useOrchimateConfig();
  useFathom(config?.fathom);

  return (
    <OrchestraSpecManagerProvider>
      <Outlet />
    </OrchestraSpecManagerProvider>
  );
}

// Parent route for routes in the context of a spec
function SlugRoute() {
  const orchestraSpecManager = useOrchestraSpecManager();
  const specIdentifier = useSpecIdentifier();

  const specInfo =
    (specIdentifier && orchestraSpecManager?.getSpecInfo(specIdentifier)) ||
    null;
  const [orchestraSpec, loadingOrchestraSpec] = useOrchestraSpec(specInfo);

  const orchestraSpecContext = useMemo(
    () =>
      orchestraSpec && specInfo
        ? {
            info: specInfo,
            spec: orchestraSpec,
          }
        : null,
    [specInfo, orchestraSpec]
  );

  if (!orchestraSpecManager?.ready || loadingOrchestraSpec) {
    // If the spec manager is not ready, show a skeleton until it is
    return (
      <Layout noOutlet>
        <Typography variant='h4' component='h2'>
          <Skeleton />
        </Typography>
      </Layout>
    );
  }

  if (!specInfo) {
    // If the spec manager is ready but we didn't find a spec for the given slug, that's a 404
    return (
      <Layout noOutlet>
        <NotFound />
      </Layout>
    );
  }

  return (
    <OrchestraSpecWithInfoContext.Provider value={orchestraSpecContext}>
      <SpecSearchContextProvider>
        <PathContextProvider>
          <SpecContextFathomTracking />
          <Layout />
        </PathContextProvider>
      </SpecSearchContextProvider>
    </OrchestraSpecWithInfoContext.Provider>
  );
}

const ErrorRoute = () => {
  const error = useRouteError();
  return (
    <Layout>
      <ErrorPage error={error} />
    </Layout>
  );
};

function getSingleSpecRoutes() {
  return (
    <>
      <Route path='codeSets'>
        <Route path=':codeSetName' element={<CodeSetRoute />} />
        <Route index element={<CodeSetIndexRoute />} />
      </Route>
      <Route path='components'>
        <Route path=':componentName' element={<ComponentRoute />} />
        <Route index element={<ComponentIndexRoute />} />
      </Route>
      <Route path='datatypes'>
        <Route path=':datatypeName' element={<DatatypeRoute />} />
        <Route index element={<DatatypeIndexRoute />} />
      </Route>
      <Route path='fields'>
        <Route path=':fieldName' element={<FieldRoute />} />
        <Route index element={<FieldIndexRoute />} />
      </Route>
      <Route path='groups'>
        <Route path=':groupName' element={<GroupRoute />} />
        <Route index element={<GroupIndexRoute />} />
      </Route>
      <Route path='messages'>
        <Route path=':messageName' element={<MessageRoute />} />
        <Route index element={<MessageIndexRoute />} />
      </Route>
      <Route path='search' element={<SearchRoute />} />
      <Route path='*' element={<NotFound />} />
      <Route index element={<SpecHomeRoute />} />
    </>
  );
}

function Router() {
  const routes = createRoutesFromElements(
    <Route path='/' element={<OuterRoute />} errorElement={<ErrorRoute />}>
      <Route
        id={ORCH_HUB_OFFICIAL_SPEC_ROUTE_ID}
        path='/-/:slug/:version'
        element={<SlugRoute />}
      >
        {getSingleSpecRoutes()}
      </Route>
      <Route
        id={ORCH_HUB_GROUP_SPEC_ROUTE_ID}
        path='/r/:orchHubGroup/:slug/:version'
        element={<SlugRoute />}
      >
        {getSingleSpecRoutes()}
      </Route>
      <Route id={ORCHIMATE_SPEC_ROUTE_ID} path='/:slug' element={<SlugRoute />}>
        {getSingleSpecRoutes()}
      </Route>
      <Route
        path='/mark-internal-user'
        loader={() => {
          // This doesn't seem to actually prevent it from alerting twice in dev, I assume because it's
          // an async call under the hood. Doesn't seem worth trying harder.
          if (isTrackingEnabled()) {
            blockTrackingForMe();
          }
          return redirect('/');
        }}
      />
      <Route
        path='/unmark-internal-user'
        loader={() => {
          // like above, called twice in dev
          if (!isTrackingEnabled()) {
            enableTrackingForMe();
          }
          return redirect('/');
        }}
      />
      <Route
        index
        element={
          <Layout>
            <HomeRoute />
          </Layout>
        }
      />
      <Route
        path='*'
        element={
          <Layout>
            <NotFound />
          </Layout>
        }
      />
    </Route>
  );

  const router = createBrowserRouter(routes);
  return <RouterProvider router={router} />;
}

export function App() {
  return (
    <HelmetProvider>
      <ErrorBoundary
        fallbackRender={({ error }) => <ErrorPage error={error} />}
      >
        <ThemeProvider theme={theme}>
          <OrchimateConfigProvider>
            <Router />
          </OrchimateConfigProvider>
        </ThemeProvider>
      </ErrorBoundary>
    </HelmetProvider>
  );
}
