import {
  Box,
  Card,
  CardContent,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  OrchestraCodeSet,
  OrchestraComponent,
  OrchestraField,
  OrchestraGroup,
  OrchestraMessage,
  OrchestraResourceBase,
} from './OrchestraSpec';
import {
  OrchestraCodeSetCodeWithParent,
  SearchableResource,
  OrchestraSpecSearch,
  SpecSearchFn,
  SpecSearchResult,
} from './specSearch';
import { ResourceLink } from './InternalLink';
import { BottomDrawerLink } from './BottomDrawer';
import { PropsWithChildren } from 'react';

const DEFAULT_NAME_COLUMN_WIDTH = '20em';

type SearchResultRenderFn<T extends SearchableResource> = (
  result: SpecSearchResult<T>
) => React.ReactNode;

// TODO: merge with utilities in tableSort.ts once orchimate!63 has been merged
type Comparator<T> = (a: T, b: T) => number;
const stringComparator: Comparator<string> = (a: string, b: string) =>
  a.localeCompare(b);
function fieldComparator<ObjectT, FieldT>(
  extractor: (any: ObjectT) => FieldT,
  comparator: Comparator<FieldT>
): Comparator<ObjectT> {
  return (a: ObjectT, b: ObjectT) => comparator(extractor(a), extractor(b));
}
// End TODO

type ResourceSortFn<T extends OrchestraResourceBase> = Comparator<T>;

const sortByName = fieldComparator<OrchestraResourceBase, string>(
  (resource) => resource.name,
  stringComparator
);

function sortByCodeSetNameAndCodeName(
  codeSetCode1: OrchestraCodeSetCodeWithParent,
  codeSetCode2: OrchestraCodeSetCodeWithParent
) {
  const byCodeSet = sortByName(codeSetCode1.codeSet, codeSetCode2.codeSet);
  if (byCodeSet !== 0) {
    return byCodeSet;
  }

  return sortByName(codeSetCode1, codeSetCode2);
}

type SearchCategory<T extends SearchableResource> = {
  title: string;
  searchFn: (specSearch: OrchestraSpecSearch) => SpecSearchFn<T>;
  resourceSortFn?: ResourceSortFn<T>;
  resourceNameRenderFn: SearchResultRenderFn<T>;
  nameColumnWidth?: string | number;
};

const mainPanelLinkRenderer = (
  result: SpecSearchResult<
    OrchestraComponent | OrchestraGroup | OrchestraMessage
  >
) => <ResourceLink orchestraResource={result.resource} />;

const bottomDrawerLinkRenderer = (
  result: SpecSearchResult<OrchestraField | OrchestraCodeSet>
) => <BottomDrawerLink resource={result.resource} />;

const messageSearch: SearchCategory<OrchestraMessage> = {
  title: 'Messages',
  searchFn: (specSearch) => specSearch.searchMessages,
  resourceNameRenderFn: mainPanelLinkRenderer,
};

const componentSearch: SearchCategory<OrchestraComponent> = {
  title: 'Components',
  searchFn: (specSearch) => specSearch.searchComponents,
  resourceNameRenderFn: mainPanelLinkRenderer,
};

const groupSearch: SearchCategory<OrchestraGroup> = {
  title: 'Groups',
  searchFn: (specSearch) => specSearch.searchGroups,
  resourceNameRenderFn: mainPanelLinkRenderer,
};

const fieldSearch: SearchCategory<OrchestraField> = {
  title: 'Fields',
  searchFn: (specSearch) => specSearch.searchFields,
  resourceNameRenderFn: bottomDrawerLinkRenderer,
};

const codeSetSearch: SearchCategory<OrchestraCodeSet> = {
  title: 'Code Sets',
  searchFn: (specSearch) => specSearch.searchCodeSets,
  resourceNameRenderFn: bottomDrawerLinkRenderer,
};

const codeSetCodeSearch: SearchCategory<OrchestraCodeSetCodeWithParent> = {
  title: 'Codes',
  searchFn: (specSearch) => specSearch.searchCodes,
  resourceSortFn: sortByCodeSetNameAndCodeName,
  resourceNameRenderFn: (
    result: SpecSearchResult<OrchestraCodeSetCodeWithParent>
  ) => (
    <>
      <BottomDrawerLink resource={result.resource.codeSet} /> /{' '}
      {result.resource.name}
    </>
  ),
  nameColumnWidth: '30em',
};

type ShowExtraColumns = Partial<{ msgType: boolean; value: boolean }>;

function getSearchResultLists(
  specSearch: OrchestraSpecSearch | null,
  searchQuery: string
): React.ReactNode[] {
  function toSearchResults<T extends SearchableResource>(
    searchCategory: SearchCategory<T>,
    options?: {
      showExtraColumns?: ShowExtraColumns;
    }
  ): React.ReactNode {
    const results =
      (specSearch && searchCategory.searchFn(specSearch)(searchQuery)) || null;

    return (
      <ResourceSearchResults
        key={searchCategory.title}
        title={searchCategory.title}
        results={results}
        resourceSortFn={searchCategory.resourceSortFn}
        resourceNameRenderFn={searchCategory.resourceNameRenderFn}
        showExtraColumns={options?.showExtraColumns || {}}
        nameColumnWidth={searchCategory.nameColumnWidth}
      />
    );
  }

  return [
    toSearchResults(messageSearch, { showExtraColumns: { msgType: true } }),
    toSearchResults(componentSearch),
    toSearchResults(groupSearch),
    toSearchResults(fieldSearch),
    toSearchResults(codeSetSearch),
    toSearchResults(codeSetCodeSearch, { showExtraColumns: { value: true } }),
  ];
}

type SearchViewProps = {
  specSearch: OrchestraSpecSearch | null;
  searchQuery: string;
};

export function SearchView({ specSearch, searchQuery }: SearchViewProps) {
  const resultsLists = getSearchResultLists(specSearch, searchQuery);

  return (
    <Box>
      <Typography variant='h4' component='h2'>
        Search Results
      </Typography>
      <Card elevation={0} sx={{ my: 2 }}>
        <CardContent>
          <Typography variant='overline' color='text.secondary' gutterBottom>
            Query
          </Typography>
          <Typography variant='h5'>{searchQuery}</Typography>
        </CardContent>
      </Card>
      {resultsLists}
    </Box>
  );
}

type ResourceSearchResultsProps<T extends SearchableResource> = {
  title: string;
  results: SpecSearchResult<T>[] | null;
  resourceSortFn?: ResourceSortFn<T>;
  resourceNameRenderFn?: SearchResultRenderFn<T>;
  showExtraColumns: ShowExtraColumns;
  nameColumnWidth?: string | number;
};

function ResourceSearchResults<T extends SearchableResource>(
  props: ResourceSearchResultsProps<T>
) {
  const resourceSortFn = props.resourceSortFn ?? sortByName;
  const resourceNameRenderFn =
    props.resourceNameRenderFn ?? ((result) => result.resource.name);
  const nameColumnWidth = props.nameColumnWidth ?? DEFAULT_NAME_COLUMN_WIDTH;

  return (
    <Box sx={{ my: 2 }}>
      <Typography variant='h5' sx={{ mb: 1 }}>
        {props.title}
      </Typography>

      <TableContainer component={Paper} elevation={0}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  width: nameColumnWidth,
                }}
              >
                Name
              </TableCell>
              {props.showExtraColumns.msgType && (
                <TableCell
                  style={{
                    width: '10em',
                  }}
                >
                  MsgType
                </TableCell>
              )}
              {props.showExtraColumns.value && (
                <TableCell
                  style={{
                    width: '10em',
                  }}
                >
                  Value
                </TableCell>
              )}
              <TableCell>Description</TableCell>
            </TableRow>
          </TableHead>
          <ResourceSearchResultsTableContent
            title={props.title}
            results={props.results}
            resourceSortFn={resourceSortFn}
            resourceNameRenderFn={resourceNameRenderFn}
            showExtraColumns={props.showExtraColumns}
          />
        </Table>
      </TableContainer>
    </Box>
  );
}

type ResourceSearchResultsTableContentProps<T extends SearchableResource> = {
  title: string;
  results: SpecSearchResult<T>[] | null;
  resourceSortFn: ResourceSortFn<T>;
  resourceNameRenderFn: SearchResultRenderFn<T>;
  showExtraColumns: ShowExtraColumns;
};

function ResourceSearchResultsTableContent<T extends SearchableResource>(
  props: ResourceSearchResultsTableContentProps<T>
) {
  if (!props.results) {
    return (
      <ResultsGroupHead showExtraColumns={props.showExtraColumns}>
        <Skeleton />
      </ResultsGroupHead>
    );
  }

  const {
    resultsMatchingMsgType,
    resultsMatchingName,
    resultsMatchingSynopsis,
    resultsMatchingValue,
  } = partitionByWhatMatched(props.results, props.resourceSortFn);

  const hasNameMatchingResults = resultsMatchingName.length > 0;
  const hasSynopsisMatchingResults = resultsMatchingSynopsis.length > 0;
  const hasMsgTypeMatchingResults = resultsMatchingMsgType.length > 0;
  const hasValueMatchingResults = resultsMatchingValue.length > 0;
  const hasNotNameMatchingResults =
    hasSynopsisMatchingResults ||
    hasMsgTypeMatchingResults ||
    hasValueMatchingResults;
  const hasAnyResults = hasNameMatchingResults || hasNotNameMatchingResults;

  return (
    <>
      {!hasAnyResults && (
        <ResultsGroupHead showExtraColumns={props.showExtraColumns}>
          &raquo; No results
        </ResultsGroupHead>
      )}
      {hasNameMatchingResults && (
        <>
          {hasNotNameMatchingResults && (
            <ResultsGroupHead showExtraColumns={props.showExtraColumns}>
              &raquo; {props.title} matching name
            </ResultsGroupHead>
          )}
          <TableBody>
            {resultsMatchingName.map((result) => (
              <ResourceSearchResultsRow
                key={result.resourceId}
                result={result}
                resourceNameRenderFn={props.resourceNameRenderFn}
                showExtraColumns={props.showExtraColumns}
              />
            ))}
          </TableBody>
        </>
      )}
      {hasMsgTypeMatchingResults && (
        <>
          <ResultsGroupHead showExtraColumns={props.showExtraColumns}>
            &raquo; {props.title} matching MsgType
          </ResultsGroupHead>
          <TableBody>
            {resultsMatchingMsgType.map((result) => (
              <ResourceSearchResultsRow
                key={result.resourceId}
                result={result}
                resourceNameRenderFn={props.resourceNameRenderFn}
                showExtraColumns={props.showExtraColumns}
              />
            ))}
          </TableBody>
        </>
      )}
      {hasSynopsisMatchingResults && (
        <>
          <ResultsGroupHead showExtraColumns={props.showExtraColumns}>
            &raquo; {props.title} matching description
          </ResultsGroupHead>
          <TableBody>
            {resultsMatchingSynopsis.map((result) => (
              <ResourceSearchResultsRow
                key={result.resourceId}
                result={result}
                resourceNameRenderFn={props.resourceNameRenderFn}
                showExtraColumns={props.showExtraColumns}
              />
            ))}
          </TableBody>
        </>
      )}
      {hasValueMatchingResults && (
        <>
          <ResultsGroupHead showExtraColumns={props.showExtraColumns}>
            &raquo; {props.title} matching value
          </ResultsGroupHead>
          <TableBody>
            {resultsMatchingValue.map((result) => (
              <ResourceSearchResultsRow
                key={result.resourceId}
                result={result}
                resourceNameRenderFn={props.resourceNameRenderFn}
                showExtraColumns={props.showExtraColumns}
              />
            ))}
          </TableBody>
        </>
      )}
    </>
  );
}

function ResourceSearchResultsRow<T extends SearchableResource>({
  result,
  resourceNameRenderFn,
  showExtraColumns: {
    msgType: showMsgTypeColumn = false,
    value: showValueColumn = false,
  },
}: {
  result: SpecSearchResult<T>;
  resourceNameRenderFn: SearchResultRenderFn<T>;
  showExtraColumns: ShowExtraColumns;
}) {
  return (
    <TableRow>
      <TableCell
        component='th'
        scope='row'
        style={{
          maxWidth: '0',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
        title={result.resource.name}
      >
        {resourceNameRenderFn(result)}
      </TableCell>
      {showMsgTypeColumn && (
        <TableCell
          style={{
            maxWidth: '0',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {result.resource.msgType}
        </TableCell>
      )}
      {showValueColumn && (
        <TableCell
          style={{
            maxWidth: '0',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {result.resource.value}
        </TableCell>
      )}
      <TableCell
        style={{
          maxWidth: '0',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {result.resource.synopsis}
      </TableCell>
    </TableRow>
  );
}

function partitionByWhatMatched<T extends SearchableResource>(
  results: SpecSearchResult<T>[],
  resourceSortFn: ResourceSortFn<T>
) {
  const resultsMatchingMsgType: SpecSearchResult<T>[] = [];
  const resultsMatchingName: SpecSearchResult<T>[] = [];
  const resultsMatchingSynopsis: SpecSearchResult<T>[] = [];
  const resultsMatchingValue: SpecSearchResult<T>[] = [];

  results.forEach((result) => {
    // Important: a result is added to only one results group, so the order here matters
    if (result.msgTypeMatches) {
      resultsMatchingMsgType.push(result);
    } else if (result.nameMatches) {
      resultsMatchingName.push(result);
    } else if (result.synopsisMatches) {
      resultsMatchingSynopsis.push(result);
    } else if (result.valueMatches) {
      resultsMatchingValue.push(result);
    } else {
      // should be unreachable
    }
  });

  const sortFn = (result1: SpecSearchResult<T>, result2: SpecSearchResult<T>) =>
    resourceSortFn(result1.resource, result2.resource);
  resultsMatchingMsgType.sort(sortFn);
  resultsMatchingName.sort(sortFn);
  resultsMatchingSynopsis.sort(sortFn);
  resultsMatchingValue.sort(sortFn);

  return {
    resultsMatchingMsgType,
    resultsMatchingName,
    resultsMatchingSynopsis,
    resultsMatchingValue,
  };
}

function ResultsGroupHead({
  children,
  showExtraColumns,
}: PropsWithChildren<{ showExtraColumns: ShowExtraColumns }>) {
  let colSpan = 2;

  // In practice, these two extra columns are display mutually exclusively, but easier to just maintain the contract
  if (showExtraColumns.msgType) {
    colSpan++;
  }

  if (showExtraColumns.value) {
    colSpan++;
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell
          colSpan={colSpan}
          sx={{ fontStyle: 'italic', fontWeight: 'light' }}
        >
          {children}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
