import React from 'react';
import {
  Box,
  Link as MuiLink,
  Typography,
  TypographyProps,
} from '@mui/material';
import { privacyPolicyLink, termsOfUseLink } from './Legal';

export function Copyright(props: TypographyProps) {
  const year = new Date().getFullYear();

  return (
    <Box
      sx={{
        '.MuiLink-root': {
          color: 'inherit',
        },
      }}
    >
      <Typography
        variant='body2'
        color='text.secondary'
        align='center'
        {...props}
      >
        {`Orchimate Copyright ${year} `}
        <MuiLink
          href='https://www.atomicwire.io/'
          target='_blank'
          rel='noopener'
        >
          Atomic Wire Technology Limited
        </MuiLink>
        <br />
        {`Orchestra Copyright ${year} `}
        <MuiLink
          href='https://www.fixtrading.org/'
          target='_blank'
          rel='noopener noreferrer'
        >
          FIX Protocol Ltd
        </MuiLink>
        <br />
        {termsOfUseLink}
        <Typography
          component='span'
          variant='inherit'
          color='inherit'
          sx={{ mx: 0.5 }}
        >
          |
        </Typography>
        {privacyPolicyLink}
      </Typography>
    </Box>
  );
}
