import { getOrchimateVersion } from './metadata';
import { getErrorText } from './utils';

const ORCHIMATE_EMAIL = 'orchimate@atomicwire.io';
const COMMON_FOOTER = `
Thanks,
[Your Name]
[Your Role]
[Your Contact Info]
`.trimStart();
const FOOTER_SEPARATOR =
  '-------------------------------------------------------';

type Footers = { [key: string]: string };
const getDataFooter = (extraFooters: Footers = {}) => {
  const footers = {
    'Orchimate version': getOrchimateVersion(),
    Timestamp: new Date().toISOString(),
    ...extraFooters,
  };

  return [
    FOOTER_SEPARATOR,
    ...Object.entries(footers).map(([k, v]) => `${k}: ${v}`),
  ].join('\n');
};

export type EmailComponentProps = {
  templateName: string;
  email: string;
  subject: string;
  body: string;
};

const emailProps = (
  templateName: string,
  subject: string,
  body: string,
  extraFooters: Footers = {}
): EmailComponentProps => ({
  templateName: templateName,
  email: ORCHIMATE_EMAIL,
  subject,
  body: `${body.trim()}\n\n${getDataFooter(extraFooters).trim()}`,
});

export const reportIssueEmailProps = (error?: unknown) =>
  emailProps(
    'report-issue',
    'Report issue with Orchimate',
    `
I ran into an issue while using Orchimate and wanted to let you know about it. Here’s what’s going on:

[Quick description of the problem]

Steps to Reproduce:
  1. [Step 1]
  2. [Step 2]
  3. [Step 3]

What I Expected: [What should’ve happened instead]

What Actually Happened: [What really happened]

Extras: [Screenshots or files attached if helpful]

This has caused [briefly explain how it’s affecting your experience].

${COMMON_FOOTER}
  `,
    error ? { 'Exception Details': getErrorText(error) } : {}
  );

export const suggestNewFeatureEmailProps = () =>
  emailProps(
    'suggest-new-feature',
    'Orchimate feature suggestion',
    `
I’d like to suggest a new feature for Orchimate.

[Quick description of the feature idea, and how it would improve the app or add value for users]

[Please attach any mockups or examples if you have them]
    
${COMMON_FOOTER}
`
  );

export const generalEnquiryEmailProps = () =>
  emailProps(
    'general-enquiry',
    'General enquiry regarding Orchimate',
    `
I have a general question about Orchimate that doesn’t fit into a specific category.

[Insert your question here.]

${COMMON_FOOTER}
`
  );

export const orchestraAdoptionEmailProps = () =>
  emailProps(
    'orchestra-adoption',
    'Request for assistance with Orchestra adoption',
    `
We’re looking for help with adopting Orchestra for our project/team and could use some guidance. Here’s a bit more about our situation:

Current Status: [Quick summary of how much we know or have used Orchestra so far.]

Why We’re Interested: [Explain why we think adopting Orchestra would be a good fit for us.]

What We Need: [List the kind of help we’re hoping for—training, docs, tips for implementation, etc.]

${COMMON_FOOTER}
`
  );

export const specAuthoringEmailProps = () =>
  emailProps(
    'spec-authoring',
    'Request for assistance with Orchestra specification authoring',
    `
We’re hoping you can help us create an Orchestra specification for our project/team. Here’s what we’re working on:

Scope: [A quick overview of what the spec is for and what it covers.]

Current Progress: [Any progress we’ve made so far, if applicable.]

Where We Need Help: [Specific areas we could use guidance on, like structuring, message types, content creation, or the build process.]

${COMMON_FOOTER}
`
  );

export const buildToolsEmailProps = () =>
  emailProps(
    'build-tools',
    'Request for information about Orchestra build tools',
    `
Our team is looking to incorporate Orchestra specifications into our build process and application development. We’re interested in learning more about the build tools that can help streamline this integration.

Could we set up a time to discuss how your tools could support our team in leveraging Orchestra specifications within our build chain and application development?

${COMMON_FOOTER}
`
  );

export const orchestraHubEmailProps = () =>
  emailProps(
    'orchestra-hub',
    'Request for information about Orchestra Hub',
    `
Our team is interested in using Orchestra Hub to publish and distribute machine-readable Orchestra specifications, as well as to host build artifacts like documentation. We’d like to learn more about how Orchestra Hub can help streamline our workflow.

Could we schedule a time to discuss how we can effectively integrate Orchestra Hub into our processes?
    
${COMMON_FOOTER}
`
  );

export const enterpriseEditionEmailProps = () =>
  emailProps(
    'enterprise-edition',
    'Expression of interest in Enterprise Edition of Orchimate',
    `
Our team has been using the standard version of Orchimate and we’re interested in learning more about the Enterprise Edition. We think the added features and benefits could be a great fit for what we need.

We’re especially curious about features like [list specific features or benefits you’re interested in].

Could we set up a demo or a quick discussion to see how the Enterprise Edition might work for us?

${COMMON_FOOTER}
`
  );
