import {
  OrchHubOrchestraSpecInfo,
  RemoteOrchestraSpecInfo,
} from './OrchestraSpecManager';
import { OrchHubSpecIdentifier } from './specIdentifier';
import { fetchOrchHubSpecList } from './orchHubApi';

export async function getOrchHubSpecInfo(
  orchestraHubUrl: string
): Promise<RemoteOrchestraSpecInfo[]> {
  const specList = await fetchOrchHubSpecList(orchestraHubUrl);

  return specList.map((spec) => {
    const specIdentifier = {
      type: 'orch-hub',
      group: spec.group || 'official',
      slug: spec.slug,
      version: spec.version,
    } satisfies OrchHubSpecIdentifier;

    return {
      type: 'remote',
      remoteType: 'orchestra-hub',
      forceReloadCount: 0,
      slug: createFullOrchHubSlug(specIdentifier),
      name: spec.name,
      url: `${orchestraHubUrl}${spec.path}`,
      orchHubSpecIdentifier: specIdentifier,
      orchHubSpecHomeUrl: createOrchHubSpecHomeUrl(
        orchestraHubUrl,
        specIdentifier
      ),
    };
  });
}

export function createSpecInfoForOrchHubSpec(
  orchestraHubUrl: string,
  specIdentifier: OrchHubSpecIdentifier
): OrchHubOrchestraSpecInfo {
  return {
    type: 'remote',
    remoteType: 'orchestra-hub',
    forceReloadCount: 0,
    slug: createFullOrchHubSlug(specIdentifier),
    name: `Orchestra Hub: ${specIdentifier.slug} (${specIdentifier.version})`,
    url: createOrchHubSpecXmlUrl(orchestraHubUrl, specIdentifier),
    orchHubSpecIdentifier: specIdentifier,
    orchHubSpecHomeUrl: createOrchHubSpecHomeUrl(
      orchestraHubUrl,
      specIdentifier
    ),
  };
}

export function createFullOrchHubSlug({
  group,
  slug,
  version,
}: Omit<OrchHubSpecIdentifier, 'type'>): string {
  return `${orchHubGroupPrefix(group)}/${slug}/${version}`;
}

function createOrchHubSpecXmlUrl(
  baseUrl: string,
  { group, slug, version }: OrchHubSpecIdentifier
): string {
  const prefix = orchHubGroupPrefix(group);
  return `${baseUrl}/_static/specs/${prefix}/${slug}/${version}/${slug}-${version}.xml`;
}

export function createOrchHubSpecHomeUrl(
  baseUrl: string,
  specIdentifier: Omit<OrchHubSpecIdentifier, 'type'>
): string {
  return `${baseUrl}/${orchHubGroupPrefix(specIdentifier.group)}/${specIdentifier.slug}`;
}

function orchHubGroupPrefix(group: string): string {
  return group === 'official' ? '-' : `r/${group}`;
}
