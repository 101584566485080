import React, { PropsWithChildren } from 'react';
import {
  Box,
  Container,
  Divider,
  Link,
  LinkProps,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Typography,
} from '@mui/material';
import ArticleIcon from '@mui/icons-material/ArticleOutlined';
import { Link as RRLink } from 'react-router-dom';
import { OrchestraSpec } from './OrchestraSpec';
import {
  OrchestraSpecInfo,
  useOrchestraSpecManager,
} from './OrchestraSpecManager';
import { MarkdownLinksOnly } from './Markdown';
import { ObfuscatedEmailLink } from './ObfuscatedEmail';
import {
  generalEnquiryEmailProps,
  reportIssueEmailProps,
  suggestNewFeatureEmailProps,
} from './getInTouchEmailTemplates';
import { privacyPolicyLink, termsOfUseLink } from './Legal';
import { Navigation } from './Navigation';
import { MessageLayouts } from './MessageLayouts';
import { IndexNavigation } from './IndexNavigation';
import { useIsSmallScreenLayout } from './smallScreenLayout';

function SpecEntry({ specInfo }: { specInfo: OrchestraSpecInfo }) {
  return (
    <ListItemButton component={RRLink} to={`/${specInfo.slug}`}>
      <ListItemIcon>
        <ArticleIcon />
      </ListItemIcon>
      <ListItemText
        primary={specInfo.name}
        primaryTypographyProps={{ fontSize: 'h5.fontSize' }}
      />
    </ListItemButton>
  );
}

function NoSpec() {
  const specManager = useOrchestraSpecManager();

  const allSpecs = specManager?.orchestraSpecs ?? [];

  const localSpecListEntries = allSpecs
    .filter((specInfo) => specInfo.type === 'local')
    .map((specInfo) => <SpecEntry key={specInfo.slug} specInfo={specInfo} />);

  const orchHubOfficialSpecListEntries = allSpecs
    .filter(
      (specInfo) =>
        specInfo.type === 'remote' &&
        specInfo.remoteType === 'orchestra-hub' &&
        specInfo.orchHubSpecIdentifier.group === 'official'
    )
    .map((specInfo) => <SpecEntry key={specInfo.slug} specInfo={specInfo} />);

  const orchHubGroupSpecListEntries = allSpecs
    .filter(
      (specInfo) =>
        specInfo.type === 'remote' &&
        specInfo.remoteType === 'orchestra-hub' &&
        specInfo.orchHubSpecIdentifier.group !== 'official'
    )
    .map((specInfo) => <SpecEntry key={specInfo.slug} specInfo={specInfo} />);

  const orchHubSpecListEntries = (
    <>
      {orchHubOfficialSpecListEntries}
      {orchHubOfficialSpecListEntries.length > 0 &&
      orchHubGroupSpecListEntries.length > 0 ? (
        <Divider sx={{ my: 1 }} />
      ) : null}
      {orchHubGroupSpecListEntries}
    </>
  );

  const otherSpecListEntries = allSpecs
    .filter(
      (specInfo) =>
        specInfo.type === 'remote' && specInfo.remoteType !== 'orchestra-hub'
    )
    .map((specInfo) => <SpecEntry key={specInfo.slug} specInfo={specInfo} />);

  // If no local/bundled specs, just pre-loaded without a heading; otherwise non-empty sections with headings
  const specLists = (
    <Box data-testid='spec-list'>
      {localSpecListEntries.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <Typography variant='h6'>Local Specifications</Typography>
          <SpecList>{localSpecListEntries}</SpecList>
        </Box>
      )}
      {localSpecListEntries.length === 0 &&
      otherSpecListEntries.length === 0 ? (
        <Box sx={{ mt: 2 }}>
          <SpecList>{orchHubSpecListEntries}</SpecList>
        </Box>
      ) : (
        <Box sx={{ mt: 2 }}>
          <Typography variant='h6'>Pre-loaded Specifications</Typography>
          <SpecList>{orchHubSpecListEntries}</SpecList>
        </Box>
      )}
      {otherSpecListEntries.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <Typography variant='h6'>Other Bundled Specifications</Typography>
          <SpecList>{otherSpecListEntries}</SpecList>
        </Box>
      )}
    </Box>
  );

  return (
    <Container maxWidth='lg'>
      <Typography variant='h4' sx={{ mb: 2 }} data-testid='welcome-header'>
        Welcome to Orchimate
      </Typography>
      <Typography paragraph>
        Orchimate is a search tool designed to help you visualize and explore
        your {orchestraLink} specifications.
      </Typography>
      <Typography paragraph>
        By using Orchimate, you agree to our {termsOfUseLink} and{' '}
        {privacyPolicyLink}.
      </Typography>
      <ul>
        <li>
          Get started by uploading a specification via the "Add Local Spec"
          button, selecting one from <OrchestraHubLink />, or exploring the
          pre-loaded specifications.
        </li>
        <li>
          Orchimate supports both industry-standard and proprietary
          specifications, offering a unified solution for all your enterprise
          data.
        </li>
        <li>
          Discover how to develop Orchestra-powered applications using the{' '}
          {orchestraExamplesLink}.
        </li>
      </ul>
      <Typography paragraph>
        For assistance, use the help menu to {reportIssuesLink},{' '}
        {suggestFeaturesLink}, or send {generalInquiriesLink}.
      </Typography>
      {specLists}
      {!specManager?.ready && <Skeleton variant='rectangular' height={50} />}
    </Container>
  );
}

const orchestraLink = (
  <Link
    href='https://www.fixtrading.org/standards/fix-orchestra/'
    target='_blank'
    rel='noopener noreferrer'
  >
    Orchestra
  </Link>
);

function OrchestraHubLink(props: LinkProps) {
  return (
    <Link
      {...props}
      href='https://orchestrahub.org/'
      target='_blank'
      rel='noopener'
    >
      Orchestra Hub
    </Link>
  );
}

const orchestraExamplesLink = (
  <Link
    href='https://github.com/atomicwire/orchestra-examples'
    target='_blank'
    rel='noopener noreferrer'
  >
    Orchestra build tools and examples
  </Link>
);

const reportIssuesLink = (
  <ObfuscatedEmailLink {...reportIssueEmailProps()}>
    report issues
  </ObfuscatedEmailLink>
);

const suggestFeaturesLink = (
  <ObfuscatedEmailLink {...suggestNewFeatureEmailProps()}>
    suggest features
  </ObfuscatedEmailLink>
);

const generalInquiriesLink = (
  <ObfuscatedEmailLink {...generalEnquiryEmailProps()}>
    general inquiries
  </ObfuscatedEmailLink>
);

function SpecList({ children }: PropsWithChildren) {
  return (
    <List sx={{ mt: 1, bgcolor: 'background.paper' }} dense>
      {children}
    </List>
  );
}

// Capitalize the first letter of the given string and add a space prior to each subsequent capital letter
function titleCase(text: string): string {
  return (
    text.charAt(0).toUpperCase() + text.slice(1).replace(/([A-Z])/g, ' $1')
  );
}

function MetadataEntry({ name, value }: { name: string; value: string }) {
  return (
    <ListItem>
      <ListItemText
        primary={titleCase(name)}
        primaryTypographyProps={{ color: 'text.secondary' }}
        secondary={<MarkdownLinksOnly>{value}</MarkdownLinksOnly>}
        secondaryTypographyProps={{
          color: 'text.primary',
          fontSize: '110%',
        }}
      />
    </ListItem>
  );
}

function WithSpec({ spec }: { spec?: OrchestraSpec }) {
  const isSmallScreenLayout = useIsSmallScreenLayout();

  let listContent = (
    <ListItem>
      <ListItemText secondary={<Skeleton />} />
    </ListItem>
  );

  if (spec) {
    const metadataEntries = Array.from(spec.metadata).map(([name, value]) => (
      <MetadataEntry key={name} name={name} value={value} />
    ));

    if (metadataEntries.length > 0) {
      listContent = <>{metadataEntries}</>;
    } else {
      listContent = (
        <ListItem>
          <ListItemText secondary={<em>No metadata available</em>} />
        </ListItem>
      );
    }
  }

  return (
    <Container maxWidth='lg'>
      <Typography data-testid='spec-name-header' variant='h3'>
        {spec ? spec.name : <Skeleton />}
      </Typography>
      <Typography variant='h5' color='text.secondary'>
        {spec ? spec.version : <Skeleton />}
      </Typography>
      <List sx={{ mt: 3, bgcolor: 'background.paper' }} dense>
        {listContent}
      </List>
      {spec && isSmallScreenLayout && (
        <Box
          sx={{ mt: 4, pt: 2, bgcolor: 'background.paper' }}
          data-testid='spec-home-nav'
        >
          <Typography sx={{ ml: 2 }} variant='h6' component='div'>
            Resources
          </Typography>
          <List component='nav' sx={{ pt: 0 }}>
            <Navigation orchestraSpec={spec} />
          </List>
          <Divider sx={{ mt: 2 }} />
          <IndexNavigation />
          <Divider sx={{ mt: 2 }} />
          <MessageLayouts orchestraSpec={spec} />
        </Box>
      )}
    </Container>
  );
}

export function Home() {
  return <NoSpec />;
}

export function SpecHome({ orchestraSpec }: { orchestraSpec?: OrchestraSpec }) {
  return <WithSpec spec={orchestraSpec} />;
}
