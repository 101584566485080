import React, { HTMLAttributes, useState } from 'react';
import {
  Autocomplete,
  Box,
  FilterOptionsState,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import { matchSorter } from 'match-sorter';
import SearchIcon from '@mui/icons-material/Search';
import {
  OrchestraCodeSet,
  OrchestraComponent,
  OrchestraDatatype,
  OrchestraField,
  OrchestraGroup,
  OrchestraMessage,
} from './OrchestraSpec';
import {
  LocalOrchestraSpecInfo,
  OrchestraSpecInfo,
} from './OrchestraSpecManager';

function defaultChooserRenderOption<T>(
  chooserProps: ChooserProps<T>,
  optionProps: React.HTMLAttributes<HTMLLIElement>,
  option: T
): React.ReactNode {
  const label = chooserProps.getOptionLabel?.(option);
  const key = chooserProps.getOptionKey?.(option) || label;

  return (
    <li title={label} {...optionProps} key={key}>
      {label}
    </li>
  );
}

type ChooserProps<T> = {
  items: T[];
  value: T | null;
  label?: string;
  getOptionLabel?: (option: T) => string;
  getOptionKey?: (option: T) => string;
  setValue?: (newValue: T | null) => void;
  filterOptions?: (options: T[], state: FilterOptionsState<T>) => T[];
  renderOption?: (
    chooserProps: ChooserProps<T>,
    optionProps: React.HTMLAttributes<HTMLLIElement>,
    option: T
  ) => React.ReactNode;
  groupBy?: (option: T) => string;
  disabled?: boolean;
  blurOnSelect?: boolean;
};

export function Chooser<T>(props: ChooserProps<T>) {
  const [inputValue, setInputValue] = React.useState('');

  const renderOption = props.renderOption ?? defaultChooserRenderOption;

  const passThroughProps = {
    blurOnSelect: props.blurOnSelect,
    disabled: props.disabled,
    filterOptions: props.filterOptions,
    getOptionLabel: props.getOptionLabel,
    groupBy: props.groupBy,
    options: props.items,
    value: props.value,
  };

  return (
    <Autocomplete
      {...passThroughProps}
      inputValue={
        (props.value && props.getOptionLabel?.(props.value)) || inputValue
      }
      onInputChange={(_event, newInputValue, reason) => {
        // We want onChange to clear this box when an item is selected, so we need to ignore the events that would
        // programmatically set the input to the selected item. We do want to update the state when a user types
        // into the input, so we do continue to respond to 'input' events.
        if (reason !== 'input') {
          return;
        }

        setInputValue(newInputValue);
      }}
      sx={{ mx: 2 }}
      isOptionEqualToValue={(option: T, value: T) => {
        const optionLabel = props.getOptionLabel?.(option);
        const optionKey = props.getOptionKey?.(option) || optionLabel;
        const valueLabel = props.getOptionLabel?.(value);
        const valueKey = props.getOptionKey?.(value) || valueLabel;
        return valueKey === optionKey;
      }}
      onChange={(_e, newValue) => {
        props.setValue?.(newValue);
      }}
      renderOption={(optionProps, option) =>
        renderOption(props, optionProps, option)
      }
      renderInput={(params) => (
        <TextField label={props.label} {...params} variant='standard' />
      )}
    />
  );
}

type OrchestraSpecChooserProps = ChooserProps<OrchestraSpecInfo> & {
  reloadSpec: (specInfo: OrchestraSpecInfo) => void;
  deleteLocalSpec: (specInfo: LocalOrchestraSpecInfo) => void;
};

export function OrchestraSpecChooser(props: OrchestraSpecChooserProps) {
  function specNameOrFallback(specInfo: OrchestraSpecInfo): string {
    if (specInfo.type === 'local') {
      const spec = specInfo.localSpec;
      return `${spec.name} (${spec.version})`;
    } else {
      return specInfo.name;
    }
  }

  function renderOption(
    _chooserProps: ChooserProps<OrchestraSpecInfo>,
    optionProps: HTMLAttributes<HTMLLIElement>,
    specInfo: OrchestraSpecInfo
  ): React.ReactNode {
    const showReloadButton =
      specInfo.slug === props.value?.slug || specInfo.type === 'local';
    const showDeleteButton = specInfo.type === 'local';

    return (
      <Box
        component='li'
        sx={{ display: 'flex' }}
        {...optionProps}
        key={specInfo.slug}
      >
        <Typography component='span' sx={{ flexGrow: 1 }}>
          {specNameOrFallback(specInfo)}
        </Typography>
        {showReloadButton && (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              props.reloadSpec(specInfo);
            }}
          >
            <RefreshIcon />
          </IconButton>
        )}
        {showDeleteButton && (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              props.deleteLocalSpec(specInfo);
            }}
          >
            {' '}
            <DeleteIcon />{' '}
          </IconButton>
        )}
      </Box>
    );
  }

  function groupByLocalAndRemote(specInfo: OrchestraSpecInfo): string {
    if (specInfo.type === 'local') {
      return 'Local';
    } else if (specInfo.type === 'remote') {
      if (specInfo.remoteType === 'orchestra-hub') {
        return 'Pre-loaded';
      } else if (specInfo.remoteType === 'packaged') {
        return 'Bundled';
      }
    }

    throw Error(); // Unreachable; why is this necessary?
  }

  // Only bother grouping if there would be more than one group
  const moreThanOneGroup =
    new Set(props.items.map((specInfo) => groupByLocalAndRemote(specInfo)))
      .size > 1;
  const groupBy = moreThanOneGroup ? groupByLocalAndRemote : undefined;

  return (
    <Chooser
      {...props}
      label='Orchestra Specification'
      getOptionLabel={(specInfo) => {
        return specNameOrFallback(specInfo);
      }}
      getOptionKey={(specInfo) => specInfo.slug}
      renderOption={renderOption}
      groupBy={groupBy}
    />
  );
}

export function ComponentAndGroupChooser(
  props: ChooserProps<OrchestraComponent | OrchestraGroup>
) {
  return (
    <Chooser
      label='Component or Group'
      {...props}
      getOptionLabel={(componentOrGroup) => componentOrGroup.name}
    />
  );
}

export function MessageChooser(props: ChooserProps<OrchestraMessage>) {
  const filterOptions = (
    options: OrchestraMessage[],
    { inputValue }: { inputValue: string }
  ) =>
    matchSorter(options, inputValue, {
      keys: ['msgType', 'name'],
    });

  return (
    <Chooser
      label='Message'
      {...props}
      filterOptions={filterOptions}
      getOptionLabel={(message) =>
        message.msgType ? `${message.name} (${message.msgType})` : message.name
      }
    />
  );
}

export function FieldChooser(props: ChooserProps<OrchestraField>) {
  const filterOptions = (
    options: OrchestraField[],
    { inputValue }: { inputValue: string }
  ) =>
    matchSorter(options, inputValue, {
      keys: ['id', 'name'],
    });

  return (
    <Chooser
      label='Field'
      {...props}
      filterOptions={filterOptions}
      getOptionLabel={(field) => `${field.name} (${field.id})`}
    />
  );
}

export function DatatypeChooser(props: ChooserProps<OrchestraDatatype>) {
  const groupBy = (datatype: OrchestraDatatype) =>
    datatype.baseType !== undefined ? 'Extended Datatypes' : 'Base Datatypes';

  const itemsWithoutBaseType = props.items.filter(
    (datatype) => datatype.baseType === undefined
  );
  const itemsWithBaseType = props.items.filter(
    (datatype) => datatype.baseType !== undefined
  );
  const items = itemsWithoutBaseType.concat(itemsWithBaseType);

  return (
    <Chooser
      label='Datatype'
      {...props}
      items={items}
      getOptionLabel={(datatype) => datatype.name}
      groupBy={groupBy}
    />
  );
}

export function CodeSetChooser(props: ChooserProps<OrchestraCodeSet>) {
  return (
    <Chooser
      label='Code Set'
      {...props}
      getOptionLabel={(codeSet) => codeSet.name}
    />
  );
}

type SearchBoxProps = {
  search?: (searchQuery: string) => void;
  disabled?: boolean;
};

// Not a Chooser precisely, but shows up in the same context
export function SearchBox(props: SearchBoxProps) {
  const [searchQuery, setSearchQuery] = useState('');

  const search = () => props.search?.(searchQuery.trim());
  const searchQueryNotEmpty = searchQuery.trim() !== '';
  const searchButtonDisabled = props.disabled || !searchQueryNotEmpty;

  return (
    <Box sx={{ mx: 2 }}>
      <TextField
        data-testid='find-all'
        fullWidth={true}
        label='Find All'
        variant='standard'
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && searchQueryNotEmpty) {
            search();
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton onClick={search} disabled={searchButtonDisabled}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        disabled={props.disabled}
        aria-label='search'
      />
    </Box>
  );
}
