import { IconButton, ListSubheader, Menu, MenuItem } from '@mui/material';
import { HelpOutline } from '@mui/icons-material';
import React, { PropsWithChildren, ReactNode } from 'react';
import { ObfuscatedEmail } from './ObfuscatedEmail';
import {
  enterpriseEditionEmailProps,
  generalEnquiryEmailProps,
  orchestraAdoptionEmailProps,
  reportIssueEmailProps,
  suggestNewFeatureEmailProps,
  specAuthoringEmailProps,
  buildToolsEmailProps,
  orchestraHubEmailProps,
} from './getInTouchEmailTemplates';

export function AppBarGetInTouch() {
  return (
    <MenuButton idPrefix={'get-in-touch'} text={''} icon={<HelpOutline />}>
      {menuSections.map((section) => [
        <ListSubheader>{section.heading}</ListSubheader>,
        ...section.items,
      ])}
    </MenuButton>
  );
}

const menuSections: { heading: string; items: ReactNode[] }[] = [
  {
    heading: 'Contact Us',
    items: [
      emailItem('Report Defect', reportIssueEmailProps()),
      emailItem('Suggest Feature', suggestNewFeatureEmailProps()),
      emailItem('General Enquiry', generalEnquiryEmailProps()),
    ],
  },
  {
    heading: 'Services',
    items: [
      emailItem('Orchestra Adoption', orchestraAdoptionEmailProps()),
      emailItem('Authoring', specAuthoringEmailProps()),
      emailItem('Build Tools', buildToolsEmailProps()),
      emailItem('Orchestra Hub', orchestraHubEmailProps()),
      emailItem('Enterprise Edition', enterpriseEditionEmailProps()),
    ],
  },
];

function MenuButton({
  icon,
  idPrefix,
  children,
}: PropsWithChildren<{
  icon?: React.ReactNode;
  idPrefix: string;
  text: string;
}>) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = !!anchorEl;
  const handleMenuButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuButtonId = `${idPrefix}-menu-button`;
  const menuId = `${idPrefix}-menu`;

  return (
    <>
      <IconButton
        id={menuButtonId}
        aria-controls={open ? menuId : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        color='inherit'
        onClick={handleMenuButtonClick}
        size={'large'}
      >
        {icon}
      </IconButton>
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        MenuListProps={{
          'aria-labelledby': menuButtonId,
        }}
      >
        {' '}
        {children}
      </Menu>
    </>
  );
}

function emailItem(
  linkText: string,
  emailItemProps: {
    email: string;
    subject: string;
    body: string;
  }
) {
  return (
    <MenuItem component={ObfuscatedEmail} {...emailItemProps}>
      {linkText}
    </MenuItem>
  );
}
