import {
  Document as DocumentIndex,
  IndexOptions,
  SimpleDocumentSearchResultSetUnit,
  Tokenizer,
} from 'flexsearch';
import {
  HasDocumentation,
  HasId,
  HasMsgType,
  HasValue,
  OrchestraCodeSet,
  OrchestraCodeSetCode,
  OrchestraComponent,
  OrchestraField,
  OrchestraGroup,
  OrchestraMessage,
  OrchestraResourceBase,
  OrchestraSpec,
} from './OrchestraSpec';
import { noop } from './utils';
import React, {
  createContext,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { OrchestraSpecWithInfoContext } from './OrchestraSpecManager';

export type SearchableResource = OrchestraResourceBase &
  HasId &
  HasDocumentation &
  Partial<HasMsgType> &
  Partial<HasValue>;

export type SpecSearchResult<T extends SearchableResource> = {
  resourceId: number;
  resource: T;
  nameMatches: boolean;
  synopsisMatches: boolean;
  msgTypeMatches: boolean;
  valueMatches: boolean;
};

export type SpecSearchFn<T extends SearchableResource> = (
  query: string
) => SpecSearchResult<T>[];

export type OrchestraCodeSetCodeWithParent = OrchestraCodeSetCode & {
  codeSet: OrchestraCodeSet;
};

export type OrchestraSpecSearch = {
  specName: string;
  searchCodes: SpecSearchFn<OrchestraCodeSetCodeWithParent>;
  searchCodeSets: SpecSearchFn<OrchestraCodeSet>;
  searchComponents: SpecSearchFn<OrchestraComponent>;
  searchFields: SpecSearchFn<OrchestraField>;
  searchGroups: SpecSearchFn<OrchestraGroup>;
  searchMessages: SpecSearchFn<OrchestraMessage>;
};

export function createSpecSearch(spec: OrchestraSpec): OrchestraSpecSearch {
  const codeIndex = createResourceIndex(spec.getCodeSetCodes());
  const codeSetIndex = createResourceIndex(spec.getCodeSets());
  const componentIndex = createResourceIndex(spec.getComponents());
  const fieldIndex = createResourceIndex(spec.getFields());
  const groupIndex = createResourceIndex(spec.getGroups());
  const messageIndex = createResourceIndex(spec.getMessages());

  return {
    specName: spec.name,
    searchCodes: enrichCodeSetCodeWithParent(
      createSearchFn(codeIndex, (id) => spec.getCodeSetCodeById(id)),
      spec
    ),
    searchCodeSets: createSearchFn(codeSetIndex, (id) =>
      spec.getCodeSetById(id)
    ),
    searchComponents: createSearchFn(componentIndex, (id) =>
      spec.getComponentById(id)
    ),
    searchFields: createSearchFn(fieldIndex, (id) => spec.getFieldById(id)),
    searchGroups: createSearchFn(groupIndex, (id) => spec.getGroupById(id)),
    searchMessages: createSearchFn(messageIndex, (id) =>
      spec.getMessageById(id)
    ),
  };
}

function idIsNumber(id: string | number): id is number {
  return typeof id === 'number';
}

function createResourceIndex<T extends OrchestraResourceBase & HasId>(
  resources: T[]
): DocumentIndex<T> {
  // 'reverse' is only tiny bit slower than 'forward' but a bit more flexible ('full' is extremely
  // slow, even though it works quite nicely)
  const tokenize: Tokenizer = 'reverse';

  // 'msgType' and 'value' use 'strict' tokenization to search only for exact matches (still case-insensitive)
  const indexOptions: Array<IndexOptions<T> & { field: string }> = [
    { field: 'name' },
    { field: 'synopsis' },
    { field: 'msgType', tokenize: 'strict' },
    { field: 'value', tokenize: 'strict' },
  ];

  const index = new DocumentIndex<T>({
    tokenize,
    document: {
      id: 'id',
      index: indexOptions,
    },
  });

  resources.forEach((resource) => index.add(resource));

  return index;
}

function createSearchFn<T extends OrchestraResourceBase & HasId>(
  index: DocumentIndex<T>,
  resourceGetter: (id: number) => T | undefined
): SpecSearchFn<T> {
  return (query: string) => {
    const results = index.search(query);
    return enrichResults((id) => resourceGetter(id), results);
  };
}

function enrichResults<T extends SearchableResource>(
  resourceGetter: (id: number) => T | undefined,
  results: SimpleDocumentSearchResultSetUnit[]
): SpecSearchResult<T>[] {
  const resultsById = new Map<number, SpecSearchResult<T>>();

  results.forEach((fieldResult) => {
    fieldResult.result.forEach((id) => {
      if (!idIsNumber(id)) {
        return;
      }

      if (!resultsById.has(id)) {
        const resource = resourceGetter(id);
        if (!resource) {
          return;
        }

        resultsById.set(id, {
          resourceId: id,
          resource,
          nameMatches: false,
          synopsisMatches: false,
          msgTypeMatches: false,
          valueMatches: false,
        });
      }

      const result = resultsById.get(id) as SpecSearchResult<T>; // annoying cast

      // Indicate which of name and synopsis matches
      result.nameMatches ||= fieldResult.field === 'name';
      result.synopsisMatches ||= fieldResult.field === 'synopsis';
      result.msgTypeMatches ||= fieldResult.field === 'msgType';
      result.valueMatches ||= fieldResult.field === 'value';
    });
  });

  return Array.from(resultsById.values());
}

function isDefined<T>(val: T | undefined | null): val is T {
  return val !== undefined && val !== null;
}

function enrichCodeSetCodeWithParent(
  searchFn: SpecSearchFn<OrchestraCodeSetCode>,
  spec: OrchestraSpec
): SpecSearchFn<OrchestraCodeSetCodeWithParent> {
  return (query) => {
    return searchFn(query)
      .map((rawResult) => {
        const codeSetCode = rawResult.resource;
        const codeSet = spec.getCodeSetById(codeSetCode.codeSetId);
        if (codeSet === undefined) {
          return undefined; // Have to be able to filter these out when the codeSet can't be found
        }

        return {
          ...rawResult,
          resource: {
            ...codeSetCode,
            codeSet,
          },
        };
      })
      .filter(isDefined);
  };
}

type SpecSearchContext = {
  specSearch: OrchestraSpecSearch | null;
  loadSpecSearch: () => void;
};

const OrchestraSpecSearchContext = createContext<SpecSearchContext>({
  specSearch: null,
  loadSpecSearch: noop,
});

// Context provides the search index and a function to create the index when it's needed
export function SpecSearchContextProvider({
  children,
}: PropsWithChildren): ReactElement {
  const { spec } = useContext(OrchestraSpecWithInfoContext) || {};
  const [lastSpec, setLastSpec] = useState<OrchestraSpec | null>(null);

  const [specSearch, setSpecSearch] = useState<OrchestraSpecSearch | null>(
    null
  );

  const loadSpecSearch = useCallback(() => {
    if (!spec) {
      return;
    }
    setSpecSearch(createSpecSearch(spec));
  }, [spec]);

  if (spec !== lastSpec) {
    setSpecSearch(null);
    setLastSpec(spec || null);
  }

  const context = {
    specSearch,
    loadSpecSearch: loadSpecSearch,
  };

  return (
    <OrchestraSpecSearchContext.Provider value={context}>
      {children}
    </OrchestraSpecSearchContext.Provider>
  );
}

// Use this where actually searching. It uses the spec search context, but also forces the search index to be loaded.
export function useSpecSearch() {
  const { specSearch, loadSpecSearch } = useContext(OrchestraSpecSearchContext);
  // useEffect was necessary to avoid updating another component's state (OrchestraSpecSearchContext) in the caller's
  // render method
  useEffect(() => {
    // According to the docs, react "usually" doesn't prevent rendering for an effect to run, but in practice calling
    // loadSpecSearch directly in the effect here was causing rendering to wait, so call it in an immediate timeout
    const timeout = setTimeout(loadSpecSearch, 0);
    return () => clearTimeout(timeout);
  }, [loadSpecSearch]);
  return specSearch;
}
