import { Link } from '@mui/material';
import React from 'react';

export const termsOfUseLink = (
  <Link href='/legal/terms-of-use.html' target='_blank' rel='noopener'>
    Terms of Use
  </Link>
);

export const privacyPolicyLink = (
  <Link href='/legal/privacy-policy.html' target='_blank' rel='noopener'>
    Privacy Policy
  </Link>
);
