import React, { useContext } from 'react';
import { OrchestraSpecWithInfoContext } from './OrchestraSpecManager';
import { Helmet } from 'react-helmet-async';

export type LeafTitle = string | string[];

const baseTitle = process.env.REACT_APP_BASE_TITLE ?? 'Orchimate';

export type OrchimatePageMetadataProps = {
  leafTitle?: LeafTitle;
  description?: string;
  canonicalUrl?: string;
  noIndex?: boolean;
};

export function OrchimatePageMetadata({
  leafTitle = [],
  description,
  canonicalUrl,
  noIndex = false,
}: OrchimatePageMetadataProps) {
  const { spec } = useContext(OrchestraSpecWithInfoContext) || {};
  const pageTitle = assemblePageTitle(spec?.name ?? null, leafTitle);
  return (
    <Helmet>
      <title>{pageTitle}</title>
      {canonicalUrl && <link rel='canonical' href={canonicalUrl} />}
      {description && <meta name='description' content={description} />}
      {noIndex && <meta name='robots' content='noindex' />}
      <meta property='og:title' content={pageTitle} />
      {description && <meta property='og:description' content={description} />}
    </Helmet>
  );
}

/**
 * Assemble a page title as {@code $leafTitle | $specName | $baseTitle}, omitting a part if it is unset or not applicable.
 */
function assemblePageTitle(specName: string | null, leafTitle: LeafTitle) {
  const parts = [];

  if (Array.isArray(leafTitle)) {
    parts.push(...leafTitle);
  } else {
    parts.push(leafTitle);
  }

  if (specName !== null) {
    parts.push(specName);
  }

  parts.push(baseTitle);

  return parts.join(' | ');
}
