import {
  Box,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TypographyProps,
} from '@mui/material';
import {
  OrchestraCodeSet,
  OrchestraComponent,
  OrchestraDatatype,
  OrchestraField,
  OrchestraGroup,
  OrchestraMessage,
  OrchestraResourceTypeInfos,
} from './OrchestraSpec';
import { formatPedigree } from './OrchestraSpecFormatters';
import { Markdown } from './Markdown';
import { DataTypeLink, ResourceLink } from './InternalLink';
import { BottomDrawerLink } from './BottomDrawer';
import { HasId } from './OrchestraSpec';
import { OrchestraResourceBase } from './OrchestraSpec';
import { HasCategory } from './OrchestraSpec';
import { HasDocumentation } from './OrchestraSpec';
import { HasPedigree } from './OrchestraSpec';
import {
  fieldComparator,
  numberComparator,
  SortableColumns,
  SortableTableColumn,
  stringComparator,
  useSorter,
  nullsLast,
} from './tableSort';
import { useMemo } from 'react';

export const CODE_SETS_TITLE =
  OrchestraResourceTypeInfos['codeSet'].titlePlural;
export const COMPONENTS_TITLE =
  OrchestraResourceTypeInfos['component'].titlePlural;
export const DATATYPES_TITLE =
  OrchestraResourceTypeInfos['datatype'].titlePlural;
export const GROUPS_TITLE = OrchestraResourceTypeInfos['group'].titlePlural;
export const FIELDS_TITLE = OrchestraResourceTypeInfos['field'].titlePlural;
export const MESSAGES_TITLE = OrchestraResourceTypeInfos['message'].titlePlural;

const CATEGORY_COLUMN: IndexViewColumn<HasCategory> = {
  name: 'Category',
  renderer: (hasCategory) => hasCategory.category,
  sortable: true,
  comparator: fieldComparator(
    (hasCategory) => hasCategory.category,
    nullsLast(stringComparator)
  ),
};

const DATATYPE_COLUMN: IndexViewColumn<{ datatype: string }> = {
  name: 'Datatype',
  renderer: ({ datatype }) => <DataTypeLink type={datatype} />,
  sortable: true,
  comparator: fieldComparator(({ datatype }) => datatype, stringComparator),
};

const DESCRIPTION_COLUMN: IndexViewColumn<HasDocumentation> = {
  name: 'Description',
  renderer: (hasDocumentation) => (
    <Synopsis>{hasDocumentation.synopsis}</Synopsis>
  ),
};

const ID_COLUMN: IndexViewColumn<HasId> = {
  name: 'ID',
  renderer: ({ id }) => id,
  sortable: true,
  comparator: fieldComparator(({ id }) => id, numberComparator),
};

const NAME_COLUMN: Omit<IndexViewColumn<OrchestraResourceBase>, 'renderer'> = {
  name: 'Name',
  sortable: true,
  comparator: fieldComparator(({ name }) => name, stringComparator),
};

const PEDIGREE_COLUMN: IndexViewColumn<HasPedigree> = {
  name: 'Pedigree',
  renderer: formatPedigree,
};

export function CodeSetIndexView({
  codeSets,
}: {
  codeSets?: OrchestraCodeSet[];
}) {
  const columns: IndexViewColumn<OrchestraCodeSet>[] = [
    {
      ...NAME_COLUMN,
      renderer: (codeSet: OrchestraCodeSet) => (
        <BottomDrawerLink resource={codeSet} />
      ),
    },
    ID_COLUMN,
    DATATYPE_COLUMN,
    DESCRIPTION_COLUMN,
    PEDIGREE_COLUMN,
  ];

  return (
    <IndexView
      title={CODE_SETS_TITLE}
      columns={columns}
      itemIdExtractor={({ id }) => id}
      items={codeSets}
    />
  );
}

export function ComponentIndexView({
  components,
}: {
  components?: OrchestraComponent[];
}) {
  return (
    <ComponentOrGroupIndexView
      type='component'
      componentsOrGroups={components}
    />
  );
}

export function DatatypeIndexView({
  datatypes,
}: {
  datatypes?: OrchestraDatatype[];
}) {
  const columns: IndexViewColumn<OrchestraDatatype>[] = [
    {
      ...NAME_COLUMN,
      renderer: ({ name }) => name,
    },
    {
      name: 'Base Type',
      renderer: (datatype: OrchestraDatatype) => (
        <>
          {datatype.baseType === undefined && (
            <Typography
              color='grey.600'
              fontSize='inherit'
              sx={{ fontStyle: 'italic' }}
            >
              none
            </Typography>
          )}
          {datatype.baseType !== undefined && (
            <DataTypeLink type={datatype.baseType} />
          )}
        </>
      ),
      sortable: true,
      comparator: fieldComparator(
        ({ baseType }) => baseType,
        nullsLast(stringComparator)
      ),
    },
    DESCRIPTION_COLUMN,
    PEDIGREE_COLUMN,
  ];

  return (
    <IndexView
      title={DATATYPES_TITLE}
      columns={columns}
      itemIdExtractor={({ name }) => name}
      items={datatypes}
    />
  );
}

export function GroupIndexView({ groups }: { groups?: OrchestraGroup[] }) {
  return <ComponentOrGroupIndexView type='group' componentsOrGroups={groups} />;
}

export function FieldIndexView({ fields }: { fields?: OrchestraField[] }) {
  const columns: IndexViewColumn<OrchestraField>[] = [
    {
      name: 'ID\xa0(Tag)', // \xa0 == &nbsp;
      renderer: ({ id }) => id,
      sortable: true,
      comparator: fieldComparator((field) => field.id, numberComparator),
    },
    {
      ...NAME_COLUMN,
      renderer: (field: OrchestraField) => (
        <BottomDrawerLink resource={field} />
      ),
    },
    DATATYPE_COLUMN,
    DESCRIPTION_COLUMN,
    PEDIGREE_COLUMN,
  ];

  return (
    <IndexView
      title={FIELDS_TITLE}
      columns={columns}
      itemIdExtractor={({ id }) => id}
      items={fields}
    />
  );
}

export function MessageIndexView({
  messages,
}: {
  messages?: OrchestraMessage[];
}) {
  const columns: IndexViewColumn<OrchestraMessage>[] = [
    {
      name: 'Type',
      renderer: (message: OrchestraMessage) => message.msgType,
      sortable: true,
      comparator: fieldComparator(
        (message: OrchestraMessage) => message.msgType,
        nullsLast(stringComparator)
      ),
    },
    {
      ...NAME_COLUMN,
      renderer: (message: OrchestraMessage) => (
        <ResourceLink orchestraResource={message} />
      ),
    },
    ID_COLUMN,
    CATEGORY_COLUMN,
    DESCRIPTION_COLUMN,
    PEDIGREE_COLUMN,
  ];

  return (
    <IndexView
      title={MESSAGES_TITLE}
      columns={columns}
      itemIdExtractor={({ id }) => id}
      items={messages}
    />
  );
}

function ComponentOrGroupIndexView({
  type,
  componentsOrGroups,
}: {
  type: 'component' | 'group';
  componentsOrGroups?: (OrchestraComponent | OrchestraGroup)[];
}) {
  const columns: IndexViewColumn<OrchestraComponent | OrchestraGroup>[] = [
    {
      ...NAME_COLUMN,
      renderer: (componentOrGroup: OrchestraComponent | OrchestraGroup) => (
        <ResourceLink orchestraResource={componentOrGroup} />
      ),
    },
    ID_COLUMN,
    CATEGORY_COLUMN,
    DESCRIPTION_COLUMN,
    PEDIGREE_COLUMN,
  ];

  return (
    <IndexView
      title={OrchestraResourceTypeInfos[type].titlePlural}
      columns={columns}
      itemIdExtractor={({ id }) => id}
      items={componentsOrGroups}
    />
  );
}

type IndexViewColumn<T> = SortableTableColumn<T> & {
  renderer: (item: T) => React.ReactNode;
};

type IndexViewProps<T> = {
  title: string;
  columns: IndexViewColumn<T>[];
  itemIdExtractor: (item: T) => string | number;
  items?: T[];
};

/**
 * I'm aware there's a bit of wheel reinvention going on here vs. using a library, but it still seemed better to do this
 * than repeat the whole table structure each time.
 */
function IndexView<T>(props: IndexViewProps<T>) {
  const { sort, setSort, sorter } = useSorter({ columns: props.columns });
  const headerRow = (
    <TableRow>
      <SortableColumns columns={props.columns} sort={sort} setSort={setSort} />
    </TableRow>
  );

  const sortedItems = useMemo(
    () => props.items && sorter(props.items),
    [props.items, sorter]
  );

  const bodyRows = sortedItems ? (
    sortedItems.map((item) => (
      <TableRow key={props.itemIdExtractor(item)}>
        {props.columns.map((column) => (
          <TableCell key={column.name}>{column.renderer(item)}</TableCell>
        ))}
      </TableRow>
    ))
  ) : (
    <TableRow>
      {props.columns.map((column) => (
        <TableCell key={column.name}>
          <Skeleton />
        </TableCell>
      ))}
    </TableRow>
  );

  return (
    <Box>
      <Typography variant='h4' component='h2'>
        {props.title}
      </Typography>

      <Table>
        <TableHead>{headerRow}</TableHead>
        <TableBody>{bodyRows}</TableBody>
      </Table>
    </Box>
  );
}

function Synopsis({ children }: { children?: string }) {
  if (!children) {
    return null;
  }

  // Use smaller text for consistency with other text in the table
  const overrides = {
    p: {
      component: Typography,
      props: { variant: 'body2', gutterBottom: true } as TypographyProps,
    },
  };

  return (
    <Markdown forceDisplay='block' overrides={overrides}>
      {children}
    </Markdown>
  );
}
