import React, { ReactNode, useContext, useRef, useState } from 'react';
import { OrchestraSpecChooser } from './Choosers';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Navigation } from './Navigation';
import {
  Alert,
  AppBar,
  Box,
  Button,
  Chip,
  Container,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  Theme,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  LocalOrchestraSpecInfo,
  OrchestraSpecInfo,
  OrchestraSpecWithInfoContext,
  OrchHubOrchestraSpecInfo,
  useOrchestraSpecManager,
} from './OrchestraSpecManager';
import { MessageLayouts } from './MessageLayouts';
import { BottomDrawer, useIsBottomDrawerOpen } from './BottomDrawer';
import { InternalLink } from './InternalLink';
import { Copyright } from './Copyright';
import { AppBarGetInTouch } from './AppBarGetInTouch';
import { trackLocalSpecLoadError } from './fathomEvents';
import { Close, Menu, MenuOpen, OpenInNew } from '@mui/icons-material';
import { IndexNavigation } from './IndexNavigation';
import { useIsSmallScreenLayout } from './smallScreenLayout';
import { useScrollToTopOnLocationChange } from './scrollRestoration';
import { useOrchimateConfig } from './config';

function OrchestraSpecElements() {
  const specWithInfo = useContext(OrchestraSpecWithInfoContext);
  const orchestraSpecManager = useOrchestraSpecManager();
  const [errorState, setErrorState] = useState<string | null>(null);
  const navigate = useNavigate();

  function navigateToSpec(specInfo: OrchestraSpecInfo | null) {
    setErrorState(null);

    if (specInfo) {
      navigate(`../${specInfo.slug}`);
    } else {
      navigate('../');
    }
  }

  function addLocalSpec() {
    orchestraSpecManager
      ?.addNewLocalSpec()
      .then(navigateToSpec)
      .catch((e: Error) => {
        trackLocalSpecLoadError();
        console.warn('Failed to load local spec', e);
        setErrorState('Failed to load local spec');
      });
  }

  function reloadSpec(specInfo: OrchestraSpecInfo) {
    orchestraSpecManager?.reloadSpec(specInfo).catch((e: Error) => {
      console.warn('Failed to reload spec', e);
      setErrorState('Failed to reload spec');
    });
  }

  function deleteLocalSpec(specInfo: LocalOrchestraSpecInfo) {
    orchestraSpecManager?.deleteLocalSpec(specInfo);
    navigateToSpec(null);
  }

  let errorAlert = null;
  if (errorState !== null) {
    errorAlert = (
      <Alert severity='error' sx={{ mx: 2, mb: 2 }}>
        {errorState}
      </Alert>
    );
  }

  return (
    <>
      <OrchestraSpecChooser
        items={orchestraSpecManager?.orchestraSpecs || []}
        value={specWithInfo?.info || null}
        setValue={navigateToSpec}
        reloadSpec={reloadSpec}
        deleteLocalSpec={deleteLocalSpec}
      />
      <Button sx={{ m: 2 }} variant='outlined' onClick={addLocalSpec}>
        Add Local Spec
      </Button>
      {errorAlert !== null && errorAlert}
    </>
  );
}

type LayoutProps = {
  noOutlet?: boolean;
  children?: ReactNode;
};

export function Layout(props: LayoutProps) {
  const { info: orchestraSpecInfo, spec: orchestraSpec } = useContext(
    OrchestraSpecWithInfoContext
  ) || {
    info: null,
    spec: null,
  };

  const bottomDrawerOpen = useIsBottomDrawerOpen();
  // on mobile devices the left drawer takes up nearly all the screen, so we set the left nav width to be
  // the minimum of 80% of the screen width or 340 pixels to allow the user to see some of the main display panel
  const maxLeftDrawerWidthPercentage = '80vw';
  const maxLeftDrawerWidthPixels = '340px';
  const leftDrawerWidth = `min(${maxLeftDrawerWidthPercentage}, ${maxLeftDrawerWidthPixels})`;
  const negativeLeftDrawerWidth = `max(-${maxLeftDrawerWidthPercentage}, -${maxLeftDrawerWidthPixels})`;

  const mainContentRef = useRef<HTMLElement>(null);
  useScrollToTopOnLocationChange(mainContentRef);

  const smallScreen = useIsSmallScreenLayout();

  // for small screens, use a 'temporary' drawer which overlays the main content rather sitting beside it
  const isTempDrawer = smallScreen;

  // default the left drawer to closed on small screens
  const leftDrawerDefaultOpen = !smallScreen;
  const [leftDrawerOpen, setLeftDrawerOpen] = useState(leftDrawerDefaultOpen);

  const location = useLocation();
  const [lastLocation, setLastLocation] = useState(location);

  // when we're on small screens, force the left drawer to the default state on navigation. this has the effect of
  // opening the drawer when we get to the slug home and closing the drawer when we navigate to orchimate home or a content page
  if (location !== lastLocation) {
    setLastLocation(location);
    if (smallScreen) {
      setLeftDrawerOpen(leftDrawerDefaultOpen);
    }
  }

  const drawerPushesMainContentWhenOpen = !isTempDrawer;
  const drawerPushingMainContent =
    drawerPushesMainContentWhenOpen && leftDrawerOpen;

  // creates a transition for anything in the main content (to the right of the left drawer) so that it will slide
  // in and out with the drawer
  const mainContentTransition = (props: string | string[], theme: Theme) => {
    if (!drawerPushesMainContentWhenOpen) {
      return null;
    }

    return theme.transitions.create(
      props,
      drawerPushingMainContent
        ? {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }
        : {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }
    );
  };

  const headerSpecNameClassName = 'orchimate-header-spec-name';

  return (
    <Box
      sx={{
        display: 'flex',
        height: bottomDrawerOpen ? '60vh' : '100vh',
      }}
    >
      <CssBaseline />
      <AppBar
        sx={(theme) => ({
          ...(drawerPushingMainContent && { marginLeft: leftDrawerWidth }),
          width: drawerPushingMainContent
            ? `calc(100% - ${leftDrawerWidth})`
            : '100%',
          transition: mainContentTransition(['margin', 'width'], theme),
        })}
      >
        <Toolbar sx={{ alignItems: 'center' }}>
          {!leftDrawerOpen && (
            <Tooltip title='Open navigation menu'>
              <IconButton
                color='inherit'
                onClick={() => setLeftDrawerOpen(true)}
                edge='start'
                sx={{ mr: 2 }}
                data-testid='open-left-drawer-button'
              >
                <Menu />
              </IconButton>
            </Tooltip>
          )}
          <Typography
            component='h1'
            color='inherit'
            noWrap
            sx={{ flexGrow: 1 }}
          >
            <Typography component='span' color='inherit' variant='h5'>
              <Typography
                component={InternalLink}
                color='inherit'
                variant='h5'
                to='/'
              >
                Orchimate
              </Typography>
              <Typography
                component='span'
                variant='caption'
                sx={{
                  verticalAlign: 'super',
                  textTransform: 'uppercase',
                }}
              >
                {' '}
                alpha
              </Typography>
            </Typography>
            {!orchestraSpecInfo && (
              <Box
                sx={{ display: 'inline-flex', gap: 1, alignItems: 'center' }}
              >
                <Typography variant='h6' sx={{ ml: 2 }}>
                  <OrchestraHubButton />
                </Typography>
              </Box>
            )}
            {orchestraSpecInfo && (
              <>
                <Typography
                  component='span'
                  variant='h6'
                  color='inherit'
                  sx={{ mx: 1 }}
                >
                  {' / '}
                </Typography>
                <Box
                  sx={{ display: 'inline-flex', gap: 1, alignItems: 'center' }}
                >
                  <Typography
                    sx={{
                      display: 'inline-flex',
                      gap: 1,
                      alignItems: 'baseline',
                      '&:hover': {
                        [`.${headerSpecNameClassName}`]: {
                          textDecoration: 'underline',
                        },
                      },
                    }}
                    component={InternalLink}
                    variant='h6'
                    color='inherit'
                    to={`/${orchestraSpecInfo.slug}`}
                    underline='none'
                  >
                    <span className={headerSpecNameClassName}>
                      {orchestraSpec.name}
                    </span>
                    <Typography
                      component='span'
                      variant='body1'
                      color='grey.200'
                    >
                      {orchestraSpec.version}
                    </Typography>
                  </Typography>
                  {orchestraSpecInfo.type === 'remote' &&
                    orchestraSpecInfo.remoteType === 'orchestra-hub' && (
                      <Typography variant='h6'>
                        <OrchestraHubButton
                          orchestraSpecInfo={orchestraSpecInfo}
                        />
                      </Typography>
                    )}
                </Box>
              </>
            )}
          </Typography>
          <AppBarGetInTouch />
        </Toolbar>
      </AppBar>
      <Drawer
        variant={isTempDrawer ? 'temporary' : 'persistent'}
        open={leftDrawerOpen}
        // called for temporary drawers by the Esc key or clicking the backdrop
        onClose={() => setLeftDrawerOpen(false)}
        PaperProps={{
          sx: {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: leftDrawerWidth,
          },
        }}
      >
        <Toolbar sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Box>
            <Tooltip title='Close navigation menu'>
              <IconButton
                onClick={() => setLeftDrawerOpen(false)}
                data-testid='close-left-drawer-button'
              >
                {isTempDrawer ? <Close /> : <MenuOpen />}
              </IconButton>
            </Tooltip>
          </Box>
        </Toolbar>
        <Divider />
        <OrchestraSpecElements />
        <Divider />
        <List component='nav'>
          <Navigation orchestraSpec={orchestraSpec} />
        </List>
        <Divider sx={{ mt: 2 }} />
        <IndexNavigation disabled={orchestraSpec === null} />
        <Divider sx={{ mt: 2 }} />
        <MessageLayouts orchestraSpec={orchestraSpec} />
      </Drawer>
      <Box
        component='main'
        ref={mainContentRef}
        sx={(theme) => ({
          // for very small screens, letting this shrink below 300 results in the text cramming awkwardly when
          // the left nav is open. adding a minWidth makes it look a bit more natural
          minWidth: 300,
          flexGrow: 1,
          overflow: 'auto',
          // when the main content normally sits next to the drawer, we need a negative margin to move it back when
          // the drawer is closed
          marginLeft:
            drawerPushesMainContentWhenOpen && !leftDrawerOpen
              ? negativeLeftDrawerWidth
              : 0,
          transition: mainContentTransition('margin', theme),
        })}
      >
        <Box>
          <Toolbar />
          <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
            {!props.noOutlet && <Outlet />}
            {props.children}
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
      <BottomDrawer />
    </Box>
  );
}

function OrchestraHubButton({
  orchestraSpecInfo,
}: {
  orchestraSpecInfo?: OrchHubOrchestraSpecInfo;
}) {
  const config = useOrchimateConfig();

  const url =
    orchestraSpecInfo !== undefined
      ? orchestraSpecInfo.orchHubSpecHomeUrl
      : config?.orchestraHub.url ?? 'about:blank';

  return (
    <Chip
      sx={{
        border: '1px solid',
        borderColor: 'primary',
        // the icon is normally a bit gray and turns to the text color when
        // hovering, but we'd like it to just always be the same
        '.MuiChip-deleteIcon': {
          color: 'primary.contrastText',
          '&:hover': { color: 'primary.contrastText' },
        },
      }}
      label='Orchestra Hub'
      color='primary'
      size='small'
      clickable
      component='a'
      href={url}
      target='_blank'
      rel='noopener'
      // the only way to have an icon on the right side of the chip is to
      // pretend it's the delete icon
      onDelete={() => {}}
      deleteIcon={<OpenInNew />}
    />
  );
}
