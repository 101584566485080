import { load, trackPageview } from 'fathom-client';
import { useEffect } from 'react';
import { FathomConfig } from './config';
import { useLocation } from 'react-router-dom';
import { useBottomDrawerResource } from './BottomDrawer';
import { ResourceSlugSpecifier, slugFromResource, usePathHelper } from './Path';

export function useFathom(config?: FathomConfig) {
  const { pathname } = useLocation();

  useEffect(() => {
    if (!config) {
      return;
    }

    load(config.siteId, { auto: false });
  }, [config]);

  // Track a page view when the path changes. Providing the URL specifically
  // here because otherwise there is a race condition with the browser history
  // updating and often it records the last page rather than the new one.
  useEffect(() => {
    trackPageview({
      url: `${getOrigin()}${pathname}`,
      referrer: document.referrer,
    });
  }, [pathname]);
}

// Export a JSX element so this can easily be placed in the tree under the
// spec context provider
export function SpecContextFathomTracking() {
  useFathomInSpecContext();

  return null;
}

export function useFathomInSpecContext() {
  const { specBasePath } = usePathHelper();
  const bottomDrawerResource = useBottomDrawerResource();

  // When the bottom drawer resource changes, create synthetic page view
  useEffect(() => {
    if (!bottomDrawerResource) {
      return;
    }

    trackPageview({
      url: createSyntheticPathForBottomDrawerResource(
        specBasePath,
        bottomDrawerResource
      ),
      referrer: document.referrer,
    });
  }, [bottomDrawerResource, specBasePath]);
}

function createSyntheticPathForBottomDrawerResource(
  basePath: string,
  resource: ResourceSlugSpecifier
) {
  return `${getOrigin()}${basePath}/detailPane/${slugFromResource(resource)}`;
}

function getOrigin() {
  return window.location.origin;
}
